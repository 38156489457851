import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
 
// Vue.use(VueScrollTo);
 
// // You can also pass in the default options
Vue.use(VueScrollTo, {
    container: "body",
    duration: 100,
    easing: "linear",
    offset: 0,
    force: true,
    cancelable: true,
    // onStart: () => {
    //     this.root.scrolling = true;
    // },
    // onDone: () => {
    //     this.root.scrolling = false;
    // },
    // onCancel: () => {
    //     this.root.scrolling = false;
    // },
    x: false,
    y: true
})