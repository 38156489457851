import axios from '@/plugins/axios';

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: {}
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {};
        },
    },

    actions: {
        async fetch({ commit }, { site_id, filter = {}, sorting = null }) {
            const { data } = await axios.get(`/front/sites/${ site_id }/discounts`, { params: { filter, sorting } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { site_id, id }) {
            const { data } = await axios.get(`/front/sites/${ site_id }/discounts/${ id }`);
            commit('SET_ENTITY', data);
        },
    },

    getters: {}
}
