<template>
    <v-app>
        <component :is="layout">
            <v-main>
                <router-view />
            </v-main>
            <PopupLogin />
            <PopupProfile />
            <PopupAddress />
            <PopupCart />
        </component>
    </v-app>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import PopupLogin from './components/user/PopupLogin';
    import PopupProfile from './components/user/PopupProfile';
    import PopupAddress from './components/user/PopupAddress';
    import PopupCart from './components/order/PopupCart';

    import { imageSrc  } from './helpers';

    export default {
        name: 'Marketoria',
        metaInfo() {
            return {
                title: '',
                titleTemplate: '%s | Marketoria',
                meta: [],
                script: this.scripts || [],
                __dangerouslyDisableSanitizers: ['meta']
            }
        },
        components: {
            PopupLogin,
            PopupProfile,
            PopupAddress,
            PopupCart,
        },
        data: () => ({
            imageSrc,
        }),
        computed: {
            ...mapState('users', {
                user: state => state.user
            }),
            ...mapState('sites', {
                site: state => state.entity
            }),
            layout() {
                const layoutName = this.$route.meta.layout || 'LayoutSite';
                return () => import(`@/layouts/${layoutName}.vue`);
            },
            scripts() {
                const scripts = [];
                if (this.site.host === window.location.host && this.site.setting && this.site.setting.scripts) {
                    let index = 0;
                    for(const item of this.site.setting.scripts) {
                        index++;

                        const tmpDiv = document.createElement('div');
                        tmpDiv.innerHTML = item;
                        const tmpScripts = tmpDiv.querySelectorAll('script');
                        if(tmpScripts.length) {
                            for(const script of tmpScripts) {
                                scripts.push({
                                    type: 'text/javascript',
                                    vmid: `additional-script-${index}`,
                                    innerHTML: script.innerHTML,
                                });
                            }
                        } else {
                            scripts.push({
                                type: 'text/javascript',
                                vmid: `additional-script-${index}`,
                                innerHTML: item,
                            });
                        }
                    }
                }
                return scripts;
            }
        },
        watch: {
            site: {
                handler: function() {
                    const style = document.documentElement.style;
                    const siteStyle = this.site.style;
                    style.setProperty("--primary_color", siteStyle && siteStyle.primary_color ? siteStyle.primary_color : '#EB9F2C');
                    style.setProperty("--text_color",  '#4F4F4F');
                    style.setProperty("--header_bg",     siteStyle && siteStyle.header_bg ? siteStyle.header_bg : '#20212b');
                    style.setProperty("--button_bg",     siteStyle && siteStyle.button_bg ? siteStyle.button_bg : '#EB9F2C');
                    style.setProperty("--button_color",  siteStyle && siteStyle.button_color ? siteStyle.button_color : '#FFFFFF');
                    style.setProperty("--main_site_top_bg",  siteStyle && siteStyle.params && siteStyle.params.top_bg ? `url(${ imageSrc(siteStyle.params.top_bg) })` : '');

                    // if (this.site && this.site.setting && this.site.setting.scripts) {
                    //     let index = 0;
                    //     for(const item of this.site.setting.scripts) {
                    //         index++;
                    //         const oldScripts = document.querySelectorAll('.additional-script')
                    //         oldScripts.forEach(script => {
                    //             script.remove();
                    //         });
                    //         const script = document.createElement('script');
                    //         script.class = 'additional-script';
                    //         script.type = 'text/javascript';
                    //         script.innerHTML = item;
                    //         document.head.appendChild(script);
                    //     }
                    // }
                }
            }
        },
        async mounted() {
            console.log('current host ', window.location.host);
            store.commit('sites/CLEAR_ENTITY');
            await store.dispatch('users/fetch');
            if(this.user.id) {
                await store.dispatch('userAddresses/fetch');
            }
        },
    }
</script>