<template>
    <div class="entity-delivery-zones">
        <div class="entity-delivery-zones__map__wrapper" v-if="deliveryZones">
            <yandexMap
                class="entity-delivery-zones__map"
                :coords="center"
                zoom="12"
                :scroll-zoom="false"
                :controls="['zoomControl']"
                :behaviors="['dblClickZoom', 'rightMouseButtonMagnifier', 'multiTouch']"
            >
                <template v-for="entity in entities">
                    <template v-if="!hideZones">
                        <ymapMarker 
                            v-for="(deliveryZone, index) in deliveryZones(entity)"
                            :key="`deliveryZoneYmapMarker-${entity.id}-${index}`"
                            :marker-id="`deliveryZoneYmapMarker-${entity.id}-${index}`"
                            marker-type="Polygon"
                            :options="deliveryZone.options"
                            :coords="[deliveryZone.coordinates]"
                            :balloon="getBaloon(deliveryZone)"
                        />
                    </template>
                    <ymapMarker 
                        :key="`entityYmapMarker-${entity.id}`"
                        v-if="entity.coordinates"
                        marker-id="address"
                        marker-type="Placemark"
                        :coords="entity.coordinates"
                        :icon="markerIcon"
                    />
                </template>
            </yandexMap>
        </div>
    </div>
</template>

<script>
    import { yandexMap, ymapMarker, loadYmap } from 'vue-yandex-maps';

    import { htmlFormatted, priceFormated, priceFormatedFrom } from '../../helpers';
    
    import { ENTITY_DELIVERY_TYPE } from '@/vars';

    export default {
        name: 'DeliveryZones',
        components: {
            yandexMap, 
            ymapMarker,
        },
        props: {
            entities: {
                type: Array,
                default: () => []
            },
            hideZones: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            profile: {},
            markerIcon: {
                layout: 'default#imageWithContent',
                imageHref: '/map-icon.png',
                imageSize: [40, 40],
                imageOffset: [-20, -40],
            }
        }),
        computed: {
            center() {
                if (this.entities) {
                    const coordinates = [0, 0];
                    let length = 0;
                    for (const entity of this.entities) {
                        coordinates[0] += +entity.coordinates[0];
                        coordinates[1] += +entity.coordinates[1];
                        if(entity.coordinates[0] || entity.coordinates[1]) {
                            length += 1;
                        }
                    }
                    return [coordinates[0] / length, coordinates[1] / length];
                }
                return [37.627313, 55.751979];
            }
        },
        mounted() {
        },
        methods: {
            deliveryZones(entity) {
                let deliveries = [];
                if(entity && entity.deliveries) {
                    deliveries = entity.deliveries.filter(item => item.type === ENTITY_DELIVERY_TYPE.DELIVERY_ZONE);
                }
                return deliveries;
            },
            getBaloon(delivery) {
                const conditions = [];
                if(delivery.min_order_price) {
                    conditions.push(
                        `Минимальная сумма заказа ${priceFormated(delivery.min_order_price)}.`
                    );
                }
                if(delivery.conditions && delivery.conditions.length) {
                    for(const item of delivery.conditions) {
                        if(item.price) {
                            conditions.push(
                                `При заказе от ${priceFormatedFrom(item.min_order_price)}, стоимость доставки ${priceFormated(item.price)}.`
                            );
                        } else if(item.min_order_price) {
                            conditions.push(
                                `При заказе от ${priceFormatedFrom(item.min_order_price)}, бесплатная доставка.`
                            );
                        } else {
                            conditions.push('Бесплатная доставка');
                        }
                    }
                } else {
                    conditions.push('Бесплатная доставка');
                }
                if(delivery.comment) {
                    conditions.push('<br>' + htmlFormatted(delivery.comment));
                }
                return {
                    header: delivery.title,
                    body: conditions.join('<br>')
                }
            },
        }
    }
</script>

<style lang="scss">
.entity-delivery-zones {
    &__map {
        width: 100%;
        height: 400px;
        
        &__title {
            font-size: 17px;
            line-height: 24px;
            font-weight: 700;
        }

        &__wrapper {
            width: 100%;
            height: 400px;
            border-radius: 12px !important;
            overflow: hidden;
        }
    }
}
</style>