import axios from '../../plugins/axios';
import { copyObject } from '../../helpers';

const EMPTY_USER = {
    id: null,
    phone: '',
    code: '',
    firstname: '',
    lastname: '',
    agreement: false,
    enabled: false,
    admin: false,
    partner: false,
    deleted: false,
    created_at: '',
    updated_at: '',
    sites: []
};

export default {
    namespaced: true,

    state: {
        user: EMPTY_USER,
        auth: {}
    },

    mutations: {
        SET_USER(state, payload) {
            state.user = {
                ...state.user,
                ...payload
            };
            if(payload.token) {
                localStorage.setItem('marketoria-auth-token', payload.token);
            }
        },
        CLEAR_USER(state) {
            state.user = copyObject(EMPTY_USER);
            localStorage.removeItem('marketoria-auth-token');
        },
        SET_VALUE(state, { prop, value }) {
            state.user[prop] = value;
        },
        SET_AUTH(state, payload) {
            state.auth = payload;
        },
    },

    actions: {
        async fetch({ commit }) {
            const { data } = await axios.get('/common/auth/profile');
            commit('SET_USER', data);
            return data;
        },
        async save({ commit, state }) {
            if(state.user.id) {
                await axios.put('/common/auth/profile', state.user);
            }
        },
        async login({ commit }, { phone, agreement }) {
            const { data } = await axios.post('/common/auth/login', { phone: phone.replace(/ /g, ''), agreement });
            commit('SET_AUTH', data);
            return data;
        },
        async confirm({ state, commit }, { code }) {
            const { data } = await axios.post('/common/auth/confirm', { ...state.auth, ...{ code } });
            commit('SET_USER', {
                ...state.user, 
                ...data
            });
            return data;
        },
        async logout({ commit }) {
            commit('CLEAR_USER');
        }
    },

    getters: {}
}