<template>
    <BasePopup v-model="shown" @close="close">
        <div class="popup-profile">
            <div class="popup-profile">
                <div class="popup-profile__title">Профиль</div>
            </div>
            <div class="popup-profile__content">
                <ValidationObserver ref="validator" slim>
                    <ValidationProvider v-slot="{ errors }" rules="required">
                        <BaseTextField
                            v-model="profile.firstname"
                            label="Имя"
                            :error-messages="errors[0]"
                        />
                    </ValidationProvider>
                    <BaseTextField
                        v-model="profile.lastname"
                        label="Фамилия"
                    />
                </ValidationObserver>
                <div class="popup-profile__buttons">
                    <BaseButton
                        fill
                        @click="save">Сохранить</BaseButton>
                </div>
            </div>
        </div>
    </BasePopup>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    import BasePopup from '../common/BasePopup';
    import BaseTextField from '../common/BaseTextField';
    import BaseButton from '../common/BaseButton';

    export default {
        name: 'PopupLogin',
        components: {
            ValidationProvider,
            ValidationObserver,
            BasePopup,
            BaseTextField,
            BaseButton,
        },
        data: () => ({
            shown: false,
            profile: {},
        }),
        computed: {
            ...mapState('users', {
                user: state => state.user
            }),
        },
        mounted() {
            let this_ = this;
            this_.$root.$on('popupProfileShow', async () => {
                this_.profile = this_.user;
                this_.shown = true;
            });
            this_.$root.$on('popupProfileHide', () => {
                this_.profile = {};
                this_.shown = false;
            });
        },
        methods: {
            async save() {
                const valid = await this.$refs['validator'].validate();
                if(valid) {
                    store.commit('users/SET_USER', { ...this.user, ...this.profile });
                    await store.dispatch('users/save');
                    this.close();
                }
            },
            close() {
                this.shown = false;
                this.profile = {};
            },
        }
    }
</script>

<style lang="scss">
.popup-profile {
    &__title {
        font-size: 17px;
        line-height: 24px;
        font-weight: 700;
    }

    &__content {
        padding: 20px 0 0 0;
    }
    &__text {
        font-size: 14px;
        padding-bottom: 10px;
    }
}
</style>