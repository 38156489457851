import axios from '../../plugins/axios';
import { copyObject } from '../../helpers';

const EMPTY_FEEDBACK = {
    form: '',
    name: '',
    phone: '',
    email: '',
    comment: '',
};

export default {
    namespaced: true,

    state: {
        entity: copyObject(EMPTY_FEEDBACK)
    },

    mutations: {
        SET_ENTITY(state, payload) {
            state.entity = copyObject(EMPTY_FEEDBACK);
            state.entity = payload;
        },
    },

    actions: {
        async save({ commit, state }) {
            const response = await axios.post('/front/feedbacks', state.entity);
            return response.data;
        },
    },

    getters: {}
}
