<template>
    <div class="order-cart">
        <div class="order-cart__content">
            <div class="order-cart__content__wrap" v-if="cart.orderCreated"> 
                <div class="order-cart__empty">
                    Ваш заказ успешно создан.
                    <br><br>В ближайшее время с вами свяжется наш оператор для уточнения деталей.
                    <br><br>Номер заказа: {{ cart.orderCreated }}
                    <br><a href="#" @click.prevent="$router.push(`/${ site.code }/orders/${ cart.orderCreated }`); return false;">Перейти к заказу</a>
                    <br><br><a @click="clear">Продолжить выбор</a>
                </div>
            </div>
            <div class="order-cart__content__wrap" v-else>
                <div class="order-cart__title">
                    <div>Корзина</div>
                    <v-icon color="error" @click="clear">mdi-trash-can</v-icon>
                </div>
                <template v-if="cart.products && cart.products.length">
                    <div class="order-cart__entity" v-if="site.entities.length > 1">
                        <span>Перед оформлением заказа, пожалуйста выберите точку продажи:</span>
                        <v-menu v-if="cart.entity" bottom offset-y>
                            <template #activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <div class="order-cart__icon-button">
                                        <v-icon>mdi-map-marker</v-icon>
                                        <div class="order-cart__icon-button__button">
                                            <BaseButton medium>{{ cart.entity.title }}</BaseButton>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <v-list>
                                <template v-for="entity in site.entities">
                                    <v-list-item :key="`order-cart-addresses-${ entity.id }`" @click="setCartEntity(entity)">
                                        <v-list-item-title>
                                            {{ entity.title }} 
                                            <template v-if="entity.address">({{ entity.address }})</template>
                                        </v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>
                    </div>

                    <div class="order-cart__products">
                        <template v-for="(item, index) in cart.products">
                            <div 
                                :key="`cart-products-${item.unique_code}`"
                                class="order-cart__product"
                            >
                                <div class="order-cart__product__picture" :style="item.product.picture ? `background-image: url(${imageSrc(item.product.picture)})` : ''"></div>
                                <div class="order-cart__product__content">
                                    <div class="order-cart__product__title">{{ item.product.title }}</div>
                                    <div class="order-cart__product__options" v-if="item.options && item.options.length">{{ options(item) }}</div>
                                    <div class="order-cart__product__content__wrap">
                                        <div class="order-cart__product__price">
                                            <div class="order-cart__product__price__discount" v-if="item.full_price !== item.price_without_discount">
                                                <div>{{ priceFormated(item.full_price) }}</div>
                                                <div class="order-cart__product__price__discount__before">{{ priceFormated(item.price_without_discount) }}</div>
                                            </div>
                                            <template v-else>
                                                {{ priceFormated(item.full_price) }}
                                            </template>
                                        </div>
                                        <div class="order-cart__product__quantity">
                                            <BaseCounter
                                                :value="item.quantity"
                                                :min-value="0"
                                                @changed="setCartProductQuantity($event, index)"
                                                small
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>

                    <div class="order-cart__cutlery" v-if="cart && cart.entity && cart.entity.setting && cart.entity.setting.order_cutlery">
                        <div class="order-cart__icon-button" v-if="cart.additional_data.cutlery === 0">
                            <v-icon>mdi-silverware-variant</v-icon>
                            <div class="order-cart__icon-button__button">
                                <BaseButton 
                                    medium
                                    @click="setCartCutleryQuantity(1)">Положить приборы</BaseButton>
                            </div>
                        </div>
                        <div v-else class="order-cart__cutlery__content">
                            <div class="order-cart__cutlery__title"><v-icon>mdi-silverware-variant</v-icon>&nbsp;Приборы</div>
                            <div class="order-cart__cutlery__quantity">
                                <BaseCounter
                                    :value="cart.additional_data.cutlery"
                                    :min-value="0"
                                    @changed="setCartCutleryQuantity($event)"
                                    small
                                />
                            </div>
                        </div>
                    </div>

                    <div class="order-cart__buttons" v-if="user.id">
                        <BaseTextArea 
                            v-model="cart.additional_data.comment"
                            label="Комментарий"
                            rows="2"
                            hide-details
                        />
                    </div>
                    
                    <div class="order-cart__row" v-if="user.id">
                        <v-menu top offset-y>
                            <template #activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <div class="order-cart__icon-button">
                                        <v-icon>mdi-moped</v-icon>
                                        <div class="order-cart__icon-button__button">
                                            <BaseButton medium>{{ ENTITY_DELIVERY_TYPE_TITLE[deliveryType] }}</BaseButton>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <v-list>
                                <template v-for="item in deliveriesSelect">
                                    <v-list-item :key="`order-cart-addresses-${ item }`" @click="setDeliveryType(item)">
                                        <v-list-item-title>{{ ENTITY_DELIVERY_TYPE_TITLE[item] }}</v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>
                        <!-- <div v-if="deliveryType !== ENTITY_DELIVERY_TYPE.PICKUP"> -->
                            <div v-if="delivery">
                                <div class="order-cart__description">
                                    <span v-if="delivery.min_order_price && (cart.full_price - cart.delivery_price < delivery.min_order_price)">
                                        <b class="order-cart__description__error">Минимальная сумма заказа <nobr>{{priceFormated(delivery.min_order_price)}}</nobr>.</b>
                                    </span>
                                    <template v-else>
                                        <span v-if="delivery.condition">
                                            <b v-if="delivery.condition.price">Стоимость доставки <nobr>{{priceFormated(delivery.condition.price)}}</nobr>.</b>
                                            <b v-else>Бесплатная доставка.</b>
                                        </span>
                                        <span v-if="delivery.nextCondition">
                                            Закажите еще на <nobr>{{ priceFormated(delivery.nextCondition.min_order_price - (cart.full_price - cart.delivery_price)) }}</nobr> для 
                                            <nobr>{{ delivery.nextCondition.price ? `доставки за ${priceFormated(delivery.nextCondition.price)}` : 'бесплатной доставки' }}</nobr>.
                                        </span>
                                    </template>
                                </div>
                                <div class="order-cart__description" v-if="delivery.comment">
                                    <span v-html="htmlFormatted(delivery.comment)"></span>
                                </div>
                            </div>
                        <!-- </div> -->
                    </div>

                    <div class="order-cart__row" v-if="user.id && deliveryType !== ENTITY_DELIVERY_TYPE.PICKUP">
                        <v-menu v-if="mainAddress" top offset-y>
                            <template #activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <div class="order-cart__icon-button">
                                        <v-icon>mdi-map-marker</v-icon>
                                        <div class="order-cart__icon-button__button">
                                            <BaseButton medium>{{ shortAddressFormat(mainAddress) }}</BaseButton>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <v-list>
                                <template v-for="item in otherAddresses">
                                    <v-list-item :key="`order-cart-addresses-${ item.id }`" @click="setMainAddress(item.id)">
                                        <v-list-item-title>{{ shortAddressFormat(item) }}</v-list-item-title>
                                    </v-list-item>
                                </template>
                                <v-list-item @click="addAddress">
                                    <v-list-item-title>Добавить адрес&nbsp;<v-icon small>mdi-plus</v-icon></v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <div class="order-cart__icon-button" v-else @click="addAddress">
                            <v-icon>mdi-map-marker</v-icon>
                            <div class="order-cart__icon-button__button">
                                <BaseButton medium>Добавить адрес&nbsp;<v-icon small>mdi-plus</v-icon></BaseButton>
                            </div>
                        </div>
                        <div class="order-cart__description" v-if="!delivery">
                            <span v-if="allowDeliveryOutsideZones && mainAddress">
                                Ваш адрес не попадает ни в одну зону доставки. <br>Для уточнения стоимости с вами свяжется менеджер после оформления заказа.
                            </span>
                            <span v-else-if="!allowDeliveryOutsideZones && mainAddress">
                                <b class="order-cart__description__error">Ваш адрес не попадает ни в одну зону доставки.</b>
                            </span>
                            <span v-else>Добавьте адрес, для уточнения условий доставки.</span>
                        </div>
                        <div class="order-cart__description" v-if="deliveryType === ENTITY_DELIVERY_TYPE.DELIVERY_ZONE">
                            <a href="#" @click.prevent="shownDeliveryZones = true; return false;">Посмотреть зоны доставки на карте</a>
                        </div>
                    </div>
                    
                    <div class="order-cart__row" v-if="user.id && delivery">
                        <v-menu top offset-y>
                            <template #activator="{ on, attrs }">
                                <div v-bind="attrs" v-on="on">
                                    <div class="order-cart__icon-button">
                                        <v-icon>mdi-cash-check</v-icon>
                                        <div class="order-cart__icon-button__button">
                                            <BaseButton medium>{{ ENTITY_PAYMENT_TYPE_TITLE[paymentType] }}</BaseButton>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <v-list>
                                <template v-for="item in paymentsSelect">
                                    <v-list-item :key="`order-cart-addresses-${ item }`" @click="setPaymentType(item)">
                                        <v-list-item-title>{{ ENTITY_PAYMENT_TYPE_TITLE[item] }}</v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>
                    </div>

                    <div class="order-cart__buttons" v-if="user.id">
                        <BaseButton fill :disabled="!canDelivery || minPriceError || orderCreating" @click.stop="createOrder">
                            <span>Оформить</span>
                            <v-progress-circular
                                :size="20"
                                color="white"
                                v-if="orderCreating"
                                indeterminate
                            ></v-progress-circular>
                            <span v-else>{{ priceFormated(cart.full_price) }}</span>
                        </BaseButton>
                    </div>
                    <div class="order-cart__buttons" v-else>
                        <BaseButton fill @click.stop="$root.$emit('popupLoginShow')">
                            <v-spacer/><span>Войти</span><v-spacer/>
                        </BaseButton>
                    </div>
                </template>
                <template v-else>
                    <div class="order-cart__empty">
                        В вашей корзине <br>пока пусто
                    </div>
                </template>
                <div ref="ymap"></div>
            </div>
        </div>

        <BasePopup
            v-model="shownDeliveryZones"
            @close="shownDeliveryZones = false" >
            <div class="order-cart__delivery-zones-title">Зоны доставки</div>
            <br>
            <DeliveryZones
                :marker-coordinates="mainAddress && mainAddress.coordinates ? mainAddress.coordinates : []" 
                :delivery-zones="deliveryZones" />
        </BasePopup>
    </div>
</template>

<script>
    import store from '@/store';
    import axios from '../../plugins/axios';
    import { mapState } from 'vuex';
    import { loadYmap } from 'vue-yandex-maps';

    import { imageSrc, htmlFormatted, priceFormated, priceFormatedFrom, copyObject } from '../../helpers';
    import { shortAddressFormat } from '../../helpers/user';

    import BaseButton from '../common/BaseButton';
    import BaseCounter from '../common/BaseCounter';
    import BaseRadio from '../common/BaseRadio';
    import BaseTextArea from '../common/BaseTextArea';
    import BasePopup from '../common/BasePopup';
    import DeliveryZones from '../site/DeliveryZones';
    
    import { ENTITY_DELIVERY_TYPE, ENTITY_DELIVERY_TYPE_TITLE, ENTITY_PAYMENT_TYPE, ENTITY_PAYMENT_TYPE_TITLE } from '@/vars';

    export default {
        name: 'Cart',
        components: {
            BaseButton,
            BaseCounter,
            BaseRadio,
            BaseTextArea,
            BasePopup,
            DeliveryZones
        },
        props: {
        },
        computed: {
            ...mapState('orders', {
                cart: state => state.cart,
                order: state => state.entity,
            }),
            ...mapState('users', {
                user: state => state.user
            }),
            ...mapState('sites', {
                site: state => state.entity
            }),
            ...mapState('siteDiscounts', {
                discounts: state => state.entities
            }),
            ...mapState('userAddresses', {
                addresses: state => state.entities
            }),
            mainAddress() {
                if(this.addresses && this.addresses.length) {
                    return this.addresses.find(item => item.main);
                }
                return null;
            },
            otherAddresses() {
                if(this.addresses && this.addresses.length) {
                    return this.addresses.filter(item => !item.main);
                }
                return [];
            },
            minPriceError() {
                return this.delivery && this.delivery.min_order_price ? this.delivery.min_order_price > (this.cart.full_price - this.cart.delivery_price) : false;
            },
            deliveriesSelect() {
                let deliveries = [];
                if(this.cart.entity && this.cart.entity.deliveries) {
                    for(const item of this.cart.entity.deliveries) {
                        deliveries.push(item.type);
                    }
                    deliveries = deliveries.filter((item, index) => deliveries.indexOf(item) === index);
                }
                return deliveries;
            },
            deliveryZones() {
                let deliveries = [];
                if(this.cart.entity && this.cart.entity.deliveries) {
                    deliveries = this.cart.entity.deliveries.filter(item => item.type === ENTITY_DELIVERY_TYPE.DELIVERY_ZONE);
                }
                return deliveries;
            },
            allowDeliveryOutsideZones() {
                return this.cart.entity && this.cart.entity.setting && this.cart.entity.setting.delivery && this.cart.entity.setting.delivery.allow_delivery_outside_zones;
            },
            canDelivery() {
                return this.deliveryType === ENTITY_DELIVERY_TYPE.PICKUP 
                || (this.allowDeliveryOutsideZones && this.mainAddress)
                || (this.delivery && this.mainAddress);
            },
            paymentsSelect() {
                let payments = [];
                if (this.delivery && this.delivery.payments) {
                    if (this.delivery.payments[ENTITY_PAYMENT_TYPE.CASH]) {
                        payments.push(ENTITY_PAYMENT_TYPE.CASH);
                    }
                    if (this.delivery.payments[ENTITY_PAYMENT_TYPE.CARD]) {
                        payments.push(ENTITY_PAYMENT_TYPE.CARD);
                    }
                if (this.delivery.payments[ENTITY_PAYMENT_TYPE.ONLINE] && this.cart.entity.setting.payment.allow_online) {
                        payments.push(ENTITY_PAYMENT_TYPE.ONLINE);
                    }
                    this.paymentType = payments[0];
                }
                return payments;
            },
        },
        data: () => ({
            ENTITY_DELIVERY_TYPE, 
            ENTITY_DELIVERY_TYPE_TITLE, 
            ENTITY_PAYMENT_TYPE, 
            ENTITY_PAYMENT_TYPE_TITLE,
            imageSrc, 
            htmlFormatted,
            priceFormated,
            priceFormatedFrom,
            shortAddressFormat,
            paymentId: null,
            paymentType: null,
            payment: {},
            deliveryId: null,
            deliveryType: null,
            delivery: null,
            shownDeliveryZones: false,
            ymaps: null,
            orderCreating: false
        }),
        async mounted() {
            const { code } = this.$route.params;
            if(!this.site || !this.site.id) {
                if(code) {
                    await store.dispatch('sites/getByCode', { code });
                } else {
                    await store.dispatch('sites/getByHost', { host: window.location.host });
                }
            }
            await store.dispatch('orders/fetchCart', { code: this.site.code });
            await store.dispatch('orders/setCartSite', this.site);

            this.setDefaultDeliveryType();
            
            await loadYmap({
                apiKey: process.env.VUE_APP_YANDEX_MAPS_API_KEY,
                lang: "ru_RU",
                coordorder: "longlat",
                version: "2.1"
            });
            this.ymaps = ymaps;
        },
        watch: {
            discounts: {
                deep: true,
                async handler() {
                    if(this.discounts.length > 0) {
                        this.applyDeliveryDiscounts();
                    }   
                }
            },
            cart: {
                deep: true,
                async handler() {
                    if(this.discounts.length > 0) {
                        this.applyDeliveryDiscounts();
                    }   
                }
            },
        },
        methods: {
            async clear() {
                await store.dispatch('orders/clearCart');
                this.$emit('empty');
            },
            options(product) {
                if(product.options && product.options.length) {
                    return product.options.map(item => item.option.title).join(' • ');
                }
                return '';
            },
            setCartEntity(entity) {
                store.dispatch('orders/setCartEntity', entity);
                this.setDefaultDeliveryType();
            },
            setCartProductQuantity(quantity, index) {
                if(quantity === 0) {
                    store.dispatch('orders/removeCartProduct', index);
                } else {
                    store.dispatch('orders/setCartProductQuantity', { quantity, index: index });
                }
            },
            setCartCutleryQuantity(quantity) {
                store.commit('orders/SET_CART_CUTLERY', quantity);
            },
            addAddress() {
                this.$root.$emit('popupAddressShow');
            },
            async setMainAddress(id) {
                await store.dispatch('userAddresses/setMain', { id });
                await store.dispatch('userAddresses/fetch');
                this.updateDelivery();
            },
            setPaymentType(type) {
                this.paymentType = type;
            },
            setDefaultDeliveryType() {
                if(this.cart.entity && this.cart.entity.deliveries) {
                    const deliveries = this.cart.entity.deliveries.filter(item => item.type !== ENTITY_DELIVERY_TYPE.PICKUP);
                    if(deliveries && deliveries.length) {
                        this.setDeliveryType(deliveries[0].type);
                    } else {
                        this.setDeliveryType(this.cart.entity.deliveries[0].type);
                    }
                }
            },
            updateDelivery() {
                let delivery = null;
                let deliveries = [];
                if(this.cart.entity && this.cart.entity.deliveries) {
                    deliveries = this.cart.entity.deliveries.filter(item => item.type === this.deliveryType);
                    switch(this.deliveryType) {
                        case ENTITY_DELIVERY_TYPE.DELIVERY:
                            delivery = this.applyDeliveryConditions(deliveries[0]);
                        break;
                        case ENTITY_DELIVERY_TYPE.DELIVERY_ZONE:
                            // delivery = this.applyDeliveryConditions(deliveries[0]);
                            if(this.ymaps && this.ymaps.Map && this.mainAddress && this.mainAddress.coordinates) {
                                const myMap = new this.ymaps.Map(this.$refs.ymap, {
                                    center: this.mainAddress.coordinates,
                                    zoom: 14
                                });
                                let myPolygon = null;
                                for(const item of deliveries) {
                                    myPolygon = new this.ymaps.geometry.Polygon([item.coordinates]);
                                    myPolygon.options.setParent(myMap.options);
                                    myPolygon.setMap(myMap);
                                    if(myPolygon.contains(this.mainAddress.coordinates)) {
                                        delivery = this.applyDeliveryConditions(item);
                                    }
                                }
                            }
                        break;
                        default:
                            delivery = deliveries[0];
                        break;
                    }
                }
                this.delivery = delivery;
            },
            setDeliveryType(type) {
                this.deliveryType = type;
                this.applyDeliveryDiscounts();
                this.updateDelivery();
            },
            applyDeliveryDiscounts() {
                if(this.discounts && this.discounts.length) {
                    let discounts = [];
                    switch(this.deliveryType) {
                        case ENTITY_DELIVERY_TYPE.DELIVERY:
                        case ENTITY_DELIVERY_TYPE.DELIVERY_ZONE:
                            discounts = this.discounts.filter(item => item.delivery_type === ENTITY_DELIVERY_TYPE.DELIVERY).sort((a,b) => b.percent - a.percent);
                        break;
                        case ENTITY_DELIVERY_TYPE.PICKUP:
                            discounts = this.discounts.filter(item => item.delivery_type === ENTITY_DELIVERY_TYPE.PICKUP).sort((a,b) => b.percent - a.percent);
                        break;
                    }
                    this.cart.products.map((product, index) => {
                        const discount = discounts.find(discount => {
                            return discount.all_menus || discount.products.find(item => product.product.id === item.id);
                        });
                        if(discount && (!product.product.discount || product.product.discount.percent < discount.percent)) {
                            store.commit('orders/SET_CART_PRODUCT_TEMP_DISCOUNT', { index, discount });
                        } else {
                            store.commit('orders/SET_CART_PRODUCT_TEMP_DISCOUNT', { index, discount: null });
                        }
                        return product;
                    });
                }
            },
            applyDeliveryConditions(delivery) {
                if(delivery.conditions) {
                    let condition = null;
                    let nextCondition = delivery.conditions[0];
                    for(let index in delivery.conditions) {
                        if((this.cart.full_price - this.cart.delivery_price) >= delivery.conditions[+index].min_order_price) {
                            condition = delivery.conditions[+index];
                            nextCondition = delivery.conditions[+index + 1];
                        }
                    }
                    delivery = {
                        ...delivery,
                        condition,
                        nextCondition
                    };
                    if(delivery.condition) {
                        store.commit('orders/SET_CART_DELIVERY_PRICE', delivery.condition.price);
                    }
                }
                return delivery;
            },
            async createOrder() {
                this.orderCreating = true;
                let delivery = this.delivery;
                let payment = {
                    type: this.paymentType,
                    title: ENTITY_PAYMENT_TYPE_TITLE[this.paymentType]
                };

                if(delivery && delivery.type === ENTITY_DELIVERY_TYPE.PICKUP) {
                    store.commit('orders/SET_CART_DELIVERY_PRICE', 0);
                } else if(!delivery && this.allowDeliveryOutsideZones) {
                    store.commit('orders/SET_CART_DELIVERY_PRICE', 0);
                    delivery = { type: 'delivery', price_by_agreement: true, title: 'Доставка' };
                    payment = { type: null, title: 'По согласованию' };
                } else if(!delivery.condition && delivery.type !== ENTITY_DELIVERY_TYPE.PICKUP) {
                    store.commit('orders/SET_CART_DELIVERY_PRICE', 0);
                    delivery.price_by_agreement = true;
                }
                
                await store.dispatch('orders/create', {
                    payment,
                    delivery,
                    user_address: this.mainAddress
                });

                if (payment.type === ENTITY_PAYMENT_TYPE.ONLINE && this.cart.entity.setting.payment && this.cart.entity.setting.payment.allow_online) {
                    if (this.cart.entity.setting.payment.type === 'paygine') {
                        const { data: paygineParams } = await axios.post(`/front/paygine/register`, { orderId: this.order.id });
                        window.location.href = `${process.env.VUE_APP_PAYGINE_URL}/Purchase?${paygineParams}`;
                    } else if (this.cart.entity.setting.payment.type === 'cloudpayments' && this.cart.entity.setting.payment.cloudpayments_public_id) {
                        const publicId = this.cart.entity.setting.payment.cloudpayments_public_id;
                        const widget = new cp.CloudPayments();
                        widget.pay('charge',
                            {
                                publicId,
                                description: `Оплата заказа №${ this.order.external_id }`,
                                amount: this.order.full_price / 100,
                                currency: "RUB",
                                invoiceId: this.order.id,
                                accountId: this.user.id,
                            }
                        );
                    }
                }
                this.orderCreating = false;
            }
        }
    }
</script>

<style lang="scss">
.order-cart {
    position: relative;

    &__content {
        width: 100%;
        max-height: 100%;
        min-width: 300px;
        padding: 20px;
        background: #FFFFFF;
        border-radius: 24px;
        overflow: hidden; 

        &__wrap {
            width: calc(100% + 20px);
            height: calc(100vh - 130px);
            margin: 0 -10px;
            padding: 0 10px;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }

    &__icon-button {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__button {
            width: calc(100% - 30px);
        }
    }

    &__error {
        width: 100%;
        text-align: center;
        background: #ff7972;
        color: #ffffff;
        padding: 10px 20px;
        margin-top: 10px;
        font-size: 12px;
        line-height: 20px;
        border-radius: 12px;
    }

    &__title {
        display: flex;
        align-items: center;
        padding: 0 0 20px 0;
        font-size: 17px;
        line-height: 24px;
        font-weight: 700;

        > .v-icon {
            cursor: pointer;
            margin-left: 10px;
        }
    }

    &__delivery-zones-title {
        font-size: 17px;
        line-height: 24px;
        font-weight: 700;
    }

    &__description {
        padding-left: 33px;
        font-size: 12px;
        line-height: 16px;

        &__error {
            color: #ff7972;
        }
    }

    &__row {
        margin-top: 10px;

        .v-input--selection-controls {
            margin: 0;
            padding: 0;
        }
    }

    &__entity {
        padding: 0 10px;
    }

    &__products { 
        margin: 0 -10px;
        padding: 0 10px;
        border-top: 1px solid #F5F4F2;
    }
    &__product {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid #F5F4F2;

        &__picture {
            width: 60px;
            height: 60px;
            overflow: hidden;
            border-radius: 16px;
            background-image: url('../../assets/empty-img.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__content {
            width: calc(100% - 70px);
            padding: 4px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &__wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                margin-top: 4px;
            }
        }
        
        &__title {
            display: -webkit-box;
            overflow: hidden;
            font-size: 16px;
            margin-bottom: 4px;
            line-height: 19px;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
        }
        
        &__options {
            font-size: 12px;
            margin-bottom: 4px;
            line-height: 12px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            color: #9E9B98;
        }
        
        &__price {
            display: inline-block;
            position: relative;
            font-weight: 500;
            white-space: nowrap;
            font-size: 20px;
            line-height: 27px;

            &__discount {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                // flex-wrap: wrap;
                color: #fa6a3c;

                &__before {
                    font-size: 16px;
                    line-height: 23px;
                    color: #9E9B98;
                    text-decoration: line-through;
                    padding: 0 5px;
                }
            }
        }
    }

    &__cutlery {
        padding: 10px 0;
        border-bottom: 1px solid #F5F4F2;

        &__content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        
        &__title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 16px;
            line-height: 24px;
        }
    }

    &__buttons {
        margin-top: 10px;
        padding-left: 30px;

        .v-btn__content {
            display: flex;
            justify-content: space-between;
        }

        &__fixed {
            width: calc(100% - 70px);
            position: absolute;
            bottom: 20px;

            .v-btn__content {
                display: block;
            }
        }
    }

    &__empty {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 300px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        text-align: center;
    }
}
</style>