import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import vuetify from './plugins/vuetify';

import axios from './plugins/axios';

import './plugins/vue-meta';
import './plugins/vue-moment';
import './plugins/vue-scrollto';
import './plugins/vue-observe-visibility';
import './plugins/vee-validate';
import './styles/main.scss';

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    axios,
    vuetify,
    render: function (h) { 
        return h(App) 
    }
}).$mount('#app')
