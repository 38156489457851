<template>
    <v-text-field
        :value="value"
        class="base-textfield"
        :class="{
            'base-textfield__small': small,
        }"
        outlined
        v-bind="$attrs"
        v-on="$listeners"
    >
    </v-text-field>
</template>

<script>
    export default {
        name: 'BaseTextField',
        props: {
            small: {
                type: Boolean,
                default: false
            },
            value: {
                type: [String, Number],
                default: ''
            }
        },
        inheritAttrs: false,
    }
</script>

<style lang="scss">
.base-textfield {
    width: 100%;
    padding: 0;
    border-radius: 12px !important;
    align-self: center;

    &.v-text-field--outlined .v-label {
        top: 15px;
    }
    &.v-text-field--outlined > .v-input__control > .v-input__slot {
        background: #FFFFFF;
        min-height: 48px;
        margin-bottom: 0;
    }
    &.v-text-field.v-text-field--enclosed .v-text-field__details {
        margin-bottom: 5px;
    }

    &__small {
        border-radius: 0 !important;
        
        &.v-text-field--outlined .v-label {
            top: 7px;
        }
        &.v-text-field--outlined .v-label--active {
            transform: translateY(-14px) scale(0.75);
        }
        &.v-text-field--outlined > .v-input__control > .v-input__slot {
            min-height: 30px;
        }
    }
}
</style>
