import Vue from 'vue';
import Vuex from 'vuex';

import users from './modules/users';
import feedbacks from './modules/feedbacks';
import userAddresses from './modules/userAddresses';
import files from './modules/files';
import orders from './modules/orders';
import sites from './modules/sites';
import entities from './modules/entities';
import siteDiscounts from './modules/siteDiscounts';
import categories from './modules/categories';
import products from './modules/products';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        users,
        feedbacks,
        userAddresses,
        files,
        orders,
        sites,
        entities,
        siteDiscounts,
        categories,
        products,
    }
})
