import axios from '../../plugins/axios';

import { copyObject } from '../../helpers';
import discountHelpers from '../../helpers/discount';

const EMPTY_CART = {
    site: {},
    entity: {},
    delivery_price: 0,
    full_price: 0,
    entity_payment_id: null,
    products: [],
    additional_data: {
        cutlery: 0,
        comment: ''
    },
    orderCreated: false,
};

export default {
    namespaced: true,

    state: {
        statuses: {},
        entities: [],
        entitiesCount: 0,
        site: {},
        entity: {},
        cart: EMPTY_CART
    },

    mutations: {
        SET_STATUSES(state, payload) {
            state.statuses = payload;
        },
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = {};
        },

        /*** Cart ***/
        SET_CART(state, payload) {
            state.cart = {
                ...state.cart,
                ...payload
            };
        },
        CLEAR_CART(state) {
            state.cart = copyObject(EMPTY_CART);
        },
        SET_CART_SITE(state, payload) {
            if(state.cart.site.id !== payload.id) {
                state.cart = copyObject(EMPTY_CART);
            }
            // console.log('payload', payload);
            state.cart.site = payload;
        },
        SET_CART_ENTITY(state, payload) {
            state.cart.entity = payload;
        },
        SET_CART_CUTLERY(state, payload) {
            state.cart.additional_data.cutlery = payload;
        },
        SET_CART_ORDER_CREATED(state, payload) {
            state.cart.orderCreated = payload;
        },
        ADD_CART_PRODUCT(state, payload) {
            let added = false;
            for(const product of state.cart.products) {
                if(product.unique_code === payload.product.unique_code) {
                    product.quantity += payload.product.quantity;
                    added = true;
                }
            }
            if(!added) {
                state.cart.products.push(cartProductFormatData(payload));
            }
            state.cart.full_price = cartFullPrice(state);
        },
        SET_CART_PRODUCT_TEMP_DISCOUNT(state, payload) {
            if(payload.discount) {
                state.cart.products[payload.index].product.temp_discount = payload.discount;
                state.cart.products[payload.index].full_price = state.cart.products[payload.index].price_without_discount * (100 - payload.discount.percent) / 100;
            } else if(state.cart.products[payload.index].product.discount) {
                state.cart.products[payload.index].product.temp_discount = null;
                state.cart.products[payload.index].full_price = state.cart.products[payload.index].price_without_discount * (100 - state.cart.products[payload.index].product.discount.percent) / 100;
            } else {
                state.cart.products[payload.index].product.temp_discount = null;
                state.cart.products[payload.index].full_price = state.cart.products[payload.index].price_without_discount;
            }
            state.cart.full_price = cartFullPrice(state);
        },
        SET_CART_PRODUCT_QUANTITY(state, payload) {
            state.cart.products[payload.index].quantity = payload.quantity;
            state.cart.full_price = cartFullPrice(state);
        },
        REMOVE_CART_PRODUCT(state, index) {
            state.cart.products.splice(index, 1);
            state.cart.full_price = cartFullPrice(state);
        },
        SET_CART_DELIVERY_PRICE(state, payload) {
            state.cart.delivery_price = payload;
            state.cart.full_price = cartFullPrice(state);
        },
        /*** Cart ***/
    },

    actions: {
        async statuses({ commit }) {
            const { data } = await axios.get('/common/orders/statuses');
            commit('SET_STATUSES', data);
        },
        async fetch({ commit }, { filter = {} }) {
            const { data } = await axios.get('/front/orders', { params: { filter } });
            commit('SET_ENTITIES', data);
            return data;
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/front/orders/${ id }`);
            commit('SET_ENTITY', data);
        },
        async create({ state, commit, dispatch }, { payment = {}, delivery = {}, user_address = {} }) {
            const cart = JSON.parse(JSON.stringify(state.cart));
            cart.products = cart.products.map(item => {
                if(item.product.temp_discount) {
                    item.product.discount = item.product.temp_discount;
                    delete item.product.temp_discount;
                }
                return item;
            });
            const { data } = await axios.post(`/front/orders`, {
                ...cart,
                user_address,
                payment,
                delivery
            });
            commit('SET_ENTITY', data);
            dispatch('clearCart');
            commit('SET_CART_ORDER_CREATED', data.external_id);
        },
        async createPayment({ state, commit, dispatch }, { order_id, payment = {}}) {
            await axios.post(`/front/orders/payments`, { order_id, ...payment });
        },

        /*** Cart ***/
        async fetchCart({ commit }, { code = '' }) {
            const cart = localStorage.getItem(`marketoria-${ code }-cart`);
            if(cart) {
                commit('SET_CART', JSON.parse(cart));
            } else {
                commit('CLEAR_CART');
            }
        },
        async clearCart({ state, dispatch }) {
            state.cart.products = [];
            state.cart.full_price = cartFullPrice(state);
            state.cart.orderCreated = false;
            dispatch('cartToStorage');
            // localStorage.removeItem(`marketoria-${ state.cart.entity.code }-cart`);
            // commit('CLEAR_CART');
        },
        async setCartSite({ state, commit, dispatch }, data) {
            commit('SET_CART_SITE', data);
            if(data.entities && data.entities.length) {
                let entity = data.entities[0];
                if(state.cart.entity && state.cart.entity.id) {
                    entity = data.entities.find(item => item.id === state.cart.entity.id);
                }
                commit('SET_CART_ENTITY', entity);
            }
            dispatch('cartToStorage');
        },
        async setCartEntity({ commit, dispatch }, data) {
            commit('SET_CART_ENTITY', data);
            dispatch('cartToStorage');
        },
        async addCartProduct({ commit, dispatch }, data) {
            commit('ADD_CART_PRODUCT', data);
            dispatch('cartToStorage');
        },
        async setCartProductQuantity({ commit, dispatch }, data) {
            commit('SET_CART_PRODUCT_QUANTITY', data);
            dispatch('cartToStorage');
        },
        async removeCartProduct({ commit, dispatch }, data) {
            commit('REMOVE_CART_PRODUCT', data);
            dispatch('cartToStorage');
        },
        async cartToStorage({ state }) {
            localStorage.setItem(`marketoria-${ state.cart.site.code }-cart`, JSON.stringify(state.cart));
        },
        /*** Cart ***/
    },

    getters: {}
}

const cartFullPrice = ({ cart }) => {
    return cart.products.reduce((result, product) => {
        result += +product.full_price * product.quantity;
        return result;
    }, 0) + cart.delivery_price;
}

const cartProductFormatData = ({ product = {}, options = [] }) => {
    const full_price = product.price + options.reduce((result, item) => {
        result += +item.price;
        return result;
    }, 0);

    return {
        unique_code: product.unique_code,
        product: {
            id: product.id,
            external_id: product.external_id,
            title: product.title,
            picture: product.picture,
            price: product.price,
            weight: product.weight,
            discount: product.discount ? {
                id: product.discount.id,
                type: product.discount.type,
                title: product.discount.title,
                all_menus: product.discount.all_menus,
                percent: product.discount.percent,
            } : null
        },
        price_without_discount: full_price,
        full_price: discountHelpers.getPrice({ price: full_price, discount: product.discount }),
        quantity: product.quantity,
        options: options.map(option => ({
            option: {
                id: option.id,
                external_id: option.external_id,
                title: option.title,
                price: option.price,
                weight: option.weight,
            }
        }))
    }
};