import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VBtn,_vm._g(_vm._b({staticClass:"base-button",class:{
        'button-fill': _vm.fill,
        'button-small': _vm.small,
        'button-medium': _vm.medium,
    },attrs:{"depressed":""}},'v-btn',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }