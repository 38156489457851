<template>
    <v-btn
        class="base-button"
        :class="{
            'button-fill': fill,
            'button-small': small,
            'button-medium': medium,
        }"
        depressed
        v-bind="$attrs"
        v-on="$listeners"
    >
        <slot></slot>
    </v-btn>
</template>

<script>
    export default {
        name: 'BaseButton',
        props: {
            fill: {
                type: Boolean,
                default: false
            },
            medium: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            },
        },
        inheritAttrs: false
    }
</script>

<style lang="scss">
.base-button {
    width: 100%;
    height: 48px;
    background: #FFFFFF !important;
    border: 1px solid #BDBDBD;
    border-radius: 12px;
    &:disabled {
        opacity: 0.5;
    }

    &.v-btn:not(.v-btn--round).v-size--default {
        min-width: unset;
        height: 48px;
        padding: 0;
    }
    &.v-btn.v-btn--disabled.v-btn--has-bg {
        background: #FFFFFF !important;
    }

    .v-btn__content {
        padding: 0 20px;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        text-transform: none;
        color: #828282;
            
        .v-icon, .v-icon.v-icon {
            color: #828282;
        }
    }

    &.button-fill {
        background: var(--button_bg) !important;
        border: 1px solid var(--button_bg);

        &.v-btn.v-btn--disabled.v-btn--has-bg {
            background: var(--button_bg) !important;
        }
        
        .v-btn__content {
            color: var(--button_color);
            
            .v-icon, .v-icon.v-icon {
                color: var(--button_color);
            }
        }
    }

    &.button-small {
        height: 30px;
        border-radius: 8px;

        .v-btn__content {
            font-size: 10px;
            line-height: 14px;
        }

        &.theme--light.v-btn:not(.v-btn--round).v-size--default {
            height: 30px;
        }
    }

    &.button-medium {
        height: 38px;

        &.theme--light.v-btn:not(.v-btn--round).v-size--default {
            height: 38px;
        }
    }
}
</style>
