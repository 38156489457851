<template>
    <BasePopup v-model="shown" @close="close">
        <div class="popup-address">
            <div class="popup-address">
                <div class="popup-address__title">Адрес</div>
            </div>
            <div class="popup-address__content">
                <ValidationObserver ref="validator" slim>
                    <v-row>
                        <v-col>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <BaseTextField
                                    v-model="address.city"
                                    label="Город"
                                    @change="setMapPoint"
                                    :error-messages="errors[0]"
                                />
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <BaseTextField
                                    v-model="address.street"
                                    label="Улица"
                                    @change="setMapPoint"
                                    :error-messages="errors[0]"
                                />
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <BaseTextField
                                    v-model="address.building"
                                    label="Дом"
                                    @change="setMapPoint"
                                    :error-messages="errors[0]"
                                />
                            </ValidationProvider>
                        </v-col>
                        <v-col>
                            <ValidationProvider v-slot="{ errors }" rules="required">
                                <BaseTextField
                                    v-model="address.appartment"
                                    label="Кв/офис"
                                    :error-messages="errors[0]"
                                />
                            </ValidationProvider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <BaseTextField
                                v-model="address.entrance"
                                label="Подъезд"
                                hide-details
                            />
                        </v-col>
                        <v-col>
                            <BaseTextField
                                v-model="address.floor"
                                label="Этаж"
                                hide-details
                            />
                        </v-col>
                        <v-col>
                            <BaseTextField
                                v-model="address.intercom"
                                label="Домофон"
                                hide-details
                            />
                        </v-col>
                    </v-row>
                </ValidationObserver>

                <div class="popup-address__buttons">
                    <BaseButton
                        @click="setMapPoint">Найти на карте</BaseButton>
                </div>

                <div class="popup-address__map__wrapper">
                    <yandexMap
                        class="popup-address__map"
                        :coords="mapPoint.coordinates || [37.618833131326085, 55.75149940954284]"
                        zoom="12"
                        :scroll-zoom="false"
                        :controls="['zoomControl']"
                        :behaviors="['dblClickZoom', 'rightMouseButtonMagnifier', 'multiTouch']"
                    >
                        <template v-if="mapPoint.coordinates">
                            <ymapMarker 
                                marker-id="address"
                                marker-type="Placemark"
                                :coords="mapPoint.coordinates"
                                :icon="markerIcon"
                            />
                        </template>
                    </yandexMap>
                </div>

                <div class="popup-address__buttons">
                    <BaseButton
                        fill
                        @click="save">Сохранить</BaseButton>
                </div>
            </div>
        </div>
    </BasePopup>
</template>

<script>
    import axios from 'axios';
    import store from '@/store';
    import { mapState } from 'vuex';
    import { yandexMap, ymapMarker, loadYmap } from 'vue-yandex-maps';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    import BasePopup from '../common/BasePopup';
    import BaseTextField from '../common/BaseTextField';
    import BaseButton from '../common/BaseButton';

    export default {
        name: 'PopupAddress',
        components: {
            yandexMap,
            ymapMarker,
            ValidationProvider,
            ValidationObserver,
            BasePopup,
            BaseTextField,
            BaseButton,
        },
        data: () => ({
            shown: false,
            markerIcon: {
                layout: 'default#imageWithContent',
                imageHref: '/map-icon.png',
                imageSize: [40, 40],
                imageOffset: [-20, -40],
            },
            address: {},
            mapPoint: {}
        }),
        computed: {
            ...mapState('userAddresses', {
                current_address: state => state.entity
            }),
        },
        async mounted() {
            let this_ = this;
            this_.$root.$on('popupAddressShow', async (id) => {
                if(id) {
                    await store.dispatch('userAddresses/get', { id });
                    this_.address = this_.current_address;
                } else {
                    this_.address = {
                        main: true,
                        country: 'Россия',
                        city: 'Москва',
                        street: '',
                        building: '',
                        appartment: '',
                        entrance: '',
                        floor: '',
                    };
                }
                this_.shown = true;
            });
            this_.$root.$on('popupAddressHide', () => {
                this_.address = {};
                this_.shown = false;
            });

            await loadYmap({
                apiKey: process.env.VUE_APP_YANDEX_MAPS_API_KEY,
                lang: "ru_RU",
                coordorder: "longlat",
                version: "2.1"
            });
        },
        methods: {
            async setMapPoint() {
                if(this.address) {
                    const options = [
                        `apikey=${process.env.VUE_APP_YANDEX_GEOCODER_API_KEY}`,
                        'format=json',
                        'results=1',
                        `geocode=${this.address.city},${this.address.street},${this.address.building}`
                    ];
                    const { data } = await axios.get(`https://geocode-maps.yandex.ru/1.x/?${ options.join('&') }`);
                    
                    const mapPoints = data.response.GeoObjectCollection.featureMember.map(point => {
                        return {
                            address: point.GeoObject.metaDataProperty.GeocoderMetaData.text,
                            coordinates: point.GeoObject.Point.pos.split(' ')
                        }
                    }) || [];

                    if(mapPoints && mapPoints.length) {
                        this.mapPoint = mapPoints[0];
                    }
                }
            },
            async save() {
                const valid = await this.$refs['validator'].validate();
                if(valid) {
                    store.commit('userAddresses/SET_ENTITY', { 
                        ...this.address,
                        ...this.mapPoint
                    });
                    await store.dispatch('userAddresses/save');
                    await store.dispatch('userAddresses/fetch');
                    this.close();
                }
            },
            close() {
                this.shown = false;
                this.address = {};
            },
        }
    }
</script>

<style lang="scss">
.popup-address {
    &__title {
        font-size: 17px;
        line-height: 24px;
        font-weight: 700;
    }

    &__content {
        padding: 20px 0 0 0;
    }
    &__text {
        font-size: 14px;
        padding-bottom: 10px;
    }

    .row {
        margin: 0 -12px;
    }
    .col {
        padding: 0 12px;
    }

    &__map {
        width: 100%;
        height: 400px;

        &__wrapper {
            width: 100%;
            height: 400px;
            margin-top: 20px; 
            border-radius: 12px !important;
            overflow: hidden;
        }
    }
    &__buttons {
        padding-top: 20px;
    }
}
</style>