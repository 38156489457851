import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/lib/services/goto'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
}

Vue.use(VueRouter)

let homeRoute = {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: { 
        layout: 'LayoutEmpty' 
    }
};
const hosts = JSON.parse(process.env.VUE_APP_FRONT_HOSTS);
if(hosts.indexOf(window.location.host) < 0) {
    homeRoute = {
        path: '/',
        name: 'site',
        component: () => import('../views/site/Main.vue'),
        meta: { 
            layout: 'LayoutEmpty' 
        }
    }
}

const routes = [
    homeRoute, {
        path: '/orders',
        name: 'orders',
        component: () => import('../views/Orders.vue')
    }, {
        path: '/orders/:external_id',
        name: 'order',
        component: () => import('../views/Orders.vue')
    }, {
        path: '/sites',
        name: 'sites',
        component: () => import('../views/site/List.vue')
    },  
    
    {
        path: '/delivery',
        name: 'delivery',
        component: () => import('../views/site/Delivery.vue')
    }, {
        path: '/menu',
        name: 'full-menu',
        component: () => import('../views/site/FullMenu.vue'),
        meta: {
            hideButtons: true
        }
    }, {
        path: '/orders',
        name: 'site-orders',
        component: () => import('../views/site/Orders.vue')
    }, {
        path: '/orders/:external_id',
        name: 'site-order',
        component: () => import('../views/site/Orders.vue')
    }, 
    
    {
        path: '/:code',
        name: 'code-site',
        component: () => import('../views/site/Main.vue'),
        meta: { 
            layout: 'LayoutEmpty' 
        }
    }, {
        path: '/:code/delivery',
        name: 'code-delivery',
        component: () => import('../views/site/Delivery.vue')
    }, {
        path: '/:code/menu',
        name: 'code-full-menu',
        component: () => import('../views/site/FullMenu.vue'),
        meta: {
            hideButtons: true
        }
    }, {
        path: '/:code/orders',
        name: 'code-site-orders',
        component: () => import('../views/site/Orders.vue')
    }, {
        path: '/:code/orders/:external_id',
        name: 'code-site-order',
        component: () => import('../views/site/Orders.vue')
    }, 
    
    {
        path: '/entity/:code',
        name: 'entity',
        component: () => import('../views/site/Delivery.vue')
    }, {
        path: '/entity/:code/menu',
        name: 'entity-full-menu',
        component: () => import('../views/site/FullMenu.vue'),
        meta: {
            hideButtons: true
        }
    }, {
        path: '/entity/:code/orders',
        name: 'entity-site-orders',
        component: () => import('../views/site/Orders.vue')
    }, {
        path: '/entity/:code/orders/:external_id',
        name: 'entity-site-order',
        component: () => import('../views/site/Orders.vue')
    },
]

const router = new VueRouter({
    routes,
    base: '/',   
    mode: 'history',
    async scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            await new Promise(resolve => setTimeout(resolve, 100));
            return { selector: to.hash }
        } else 
        if (savedPosition) {
            return savedPosition;
        }  else {
            return { x: 0, y: 0 }
        }
    }
});

export default router
