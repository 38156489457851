import axios from '../../plugins/axios';

export default {
    namespaced: true,

    state: {},

    mutations: {},

    actions: {
        async upload({ commit }, { file, maxSize }) {
            const formData = new FormData();
            formData.append('file', file);
            if(maxSize){
                formData.append('maxSize', maxSize);
                // console.log(maxSize);
            }
            const { data } = await axios.post('/common/files/upload', formData);
            return data;
        },
        async delete({ commit }, { file }) {
            await axios.post('/common/files/delete', { file });
        }
    },

    getters: {}
}
