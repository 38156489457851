import axios from '../../plugins/axios';
import { copyObject } from '../../helpers';

const EMPTY_ADDRESS = {
    main: true,
    country: '',
    city: '',
    street: '',
    building: '',
    appartment: '',
    entrance: '',
    floor: '',
    intercom: '',
};

export default {
    namespaced: true,

    state: {
        entities: [],
        entitiesCount: 0,
        entity: copyObject(EMPTY_ADDRESS)
    },

    mutations: {
        SET_ENTITIES(state, payload) {
            state.entities = payload;
        },
        SET_ENTITY(state, payload) {
            state.entity = payload;
        },
        CLEAR_ENTITY(state) {
            state.entity = copyObject(EMPTY_ADDRESS);
        },
    },

    actions: {
        async fetch({ commit }) {
            const { data } = await axios.get('/front/user-addresses');
            commit('SET_ENTITIES', data);
        },
        async get({ commit }, { id }) {
            const { data } = await axios.get(`/front/user-addresses/${ id }`);
            commit('SET_ENTITY', data);
        },
        async setMain({ commit }, { id }) {
            await axios.put(`/front/user-addresses/${ id }/main`);
        },
        async save({ commit, state }) {
            let response = {};
            if(state.entity.id) {
                response = await axios.put(`/front/user-addresses/${ state.entity.id }`, state.entity);
            } else {
                response = await axios.post('/front/user-addresses', state.entity);
            }
            return response.data;
        },
        async delete({ commit }, { id }) {
            await axios.delete(`/front/user-addresses/${ id }`);
        },
    },

    getters: {}
}
