<template>
    <BasePopup v-model="shown" @close="close">
        <div class="popup-login">
            <div class="popup-login">
                <div class="popup-login__title">Авторизация</div>
            </div>
            <div v-if="showConfirm === false" class="popup-login__content">
                <ValidationObserver ref="validator_1" slim>
                    <ValidationProvider v-slot="{ errors }" rules="required">
                        <BaseTextField
                            :error-messages="errors[0]"
                            label="Телефон"
                            type="tel"
                            autocomplete="off"
                            :value="phone"
                            outlined
                            @input="onPhoneInput"
                            @change="onPhoneInput"
                            @keyup.enter="login"
                        />
                    </ValidationProvider>
                    <BaseCheckbox
                        v-model="agreement">
                        <template v-slot:label="">
                            <div @click.stop="">
                                Я принимаю <a :href="VUE_APP_DOC_SITE_AGREEMENT" target="_blank">Соглашение об использовании сайта и персональных данных</a>, <a :href="VUE_APP_DOC_USER_AGREEMENT" target="_blank">Пользовательское соглашение</a>
                            </div>
                        </template>
                    </BaseCheckbox>
                </ValidationObserver>
                <BaseButton 
                    fill
                    :disabled="!agreement || loading"
                    @click="login">
                        Далее
                        <template v-if="loading">
                            &nbsp;
                            <v-progress-circular
                                :size="20"
                                color="white"
                                indeterminate
                            ></v-progress-circular>
                        </template>
                    </BaseButton>
            </div>
            <div v-if="showConfirm === true" class="popup-login__content">
                <div class="popup-login__text"> на номер {{ phone }} отправлено СМС с кодом подтверждения</div>
                <ValidationObserver ref="validator_2" slim>
                    <ValidationProvider v-slot="{ errors }" rules="required|code">
                        <BaseTextField
                            v-model="code"
                            pattern="\d*"
                            label="Введите код из смс"
                            counter="4"
                            :error-messages="errors[0]"
                            @keyup.enter="confirm"
                            autocomplete="off"
                        />
                    </ValidationProvider>
                    <div class="popup-login__text">
                        <div v-if="secondsLeft">Отправить код повторно <span class="popup-login__send-code__timer">через {{ prettyCounter }}</span></div>
                        <a
                            v-else
                            class="popup-login__send-code"
                            @click="phoneRepeatSubmit">Отправить код повторно</a>
                    </div>
                    <div class="popup-login__buttons">
                        <BaseButton
                            fill
                            :disabled="loading"
                            @click="confirm">
                            Войти
                            <template v-if="loading">
                                &nbsp;
                                <v-progress-circular
                                    :size="20"
                                    color="white"
                                    indeterminate
                                ></v-progress-circular>
                            </template>
                        </BaseButton>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </BasePopup>
</template>

<script>
    import store from '@/store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    import BasePopup from '../common/BasePopup';
    import BaseTextField from '../common/BaseTextField';
    import BaseButton from '../common/BaseButton';
    import BaseCheckbox from '../common/BaseCheckbox';

    const { VUE_APP_DOC_SITE_AGREEMENT, VUE_APP_DOC_USER_AGREEMENT } = process.env;

    export default {
        name: 'PopupLogin',
        components: {
            ValidationProvider,
            ValidationObserver,
            BasePopup,
            BaseTextField,
            BaseButton,
            BaseCheckbox,
        },
        data: () => ({
            VUE_APP_DOC_SITE_AGREEMENT,
            VUE_APP_DOC_USER_AGREEMENT,
            phone: '+7 ',
            code: '',
            agreement: true,
            showConfirm: false,
            shown: false,
            secondsLeft: 0,
            intervalId: undefined,
            loading: false
        }),
        computed: {
            prettyCounter() {
                if (this.secondsLeft < 1) {
                    return '';
                }

                const minutes = Math.floor(this.secondsLeft / 60);
                const seconds = this.secondsLeft - minutes * 60;

                return `${minutes}:${seconds >= 10 ? seconds : `0${seconds}`}`;
            },
        },
        mounted() {
            let this_ = this;
            this_.$root.$on('popupLoginShow', async () => {
                this_.shown = true;
            });
            this_.$root.$on('popupLoginHide', () => {
                this_.shown = false;
            });
        },
        methods: {
            onPhoneInput(value) {
                value = value.replace(/[^\d]/g, '');
                if(value[0] === '7') {
                    value = value.slice(1);
                } 

                if (value.length > 8) {
                    this.phone = '+7 ' + value.replace(/(\d{3})(\d{3})(\d{2})(\d*)/, '$1 $2 $3 $4');
                } else if (value.length > 6) {
                    this.phone = '+7 ' + value.replace(/(\d{3})(\d{3})(\d*)/, '$1 $2 $3');
                } else if (value.length > 3) {
                    this.phone = '+7 ' + value.replace(/(\d{3})(\d*)/, '$1 $2');
                } else {
                    this.phone = '+7 ' + value.replace(/(\d*)/, '$1');
                }
            },
            async login() {
                if (this.agreement) {
                    this.loading = true;
                    const valid = await this.$refs['validator_1'].validate();
                    if(valid) {
                        await store.dispatch('users/login', { phone: '+' + this.phone.replace(/[^\d]/g, '') });
                        this.showConfirm = true;
                    }
                    await this.startTimer();
                    this.loading = false;
                }
            },
            async phoneRepeatSubmit() {
                this.startTimer();
                await store.dispatch('users/login', { phone: '+' + this.phone.replace(/[^\d]/g, ''), agreement: this.agreement });
            },
            async confirm() {
                const valid = await this.$refs['validator_2'].validate();
                if(valid) {
                    this.loading = true;
                    const confirmData = await store.dispatch('users/confirm', { code: this.code });
                    if(confirmData.error) {
                        this.$refs['validator_2'].errors._vee_1.push(confirmData.error);
                    } else {
                        location.reload();
                        // const user = await store.dispatch('users/fetch');
                        // await store.dispatch('userAddresses/fetch');
                        // this.close();
                        // if(!user.firstname) {
                        //     this.$root.$emit('popupProfileShow');
                        // }
                    }
                    this.loading = false;
                }
            },
            startTimer() {
                if (this.intervalId) {
                    clearInterval(this.intervalId);
                }

                this.secondsLeft = 120;

                this.intervalId = setInterval(() => {
                    if (this.secondsLeft > 0) {
                        this.secondsLeft -= 1;
                    } else {
                        clearInterval(this.intervalId);
                    }
                }, 1000);
            },
            async back() {
                this.showConfirm = false
            },
            close() {
                this.shown = false,
                this.code = '',
                this.phone = '+7 ',
                this.showConfirm = false;
            },
        }
    }
</script>

<style lang="scss">
.popup-login {
    &__title {
        font-size: 17px;
        line-height: 24px;
        font-weight: 700;
    }

    &__content {
        padding: 20px 0 0 0;
    }
    &__text {
        font-size: 14px;
        padding-bottom: 10px;
    }

    .base-checkbox {
        background: none;
        margin-bottom: 10px;
    }
}
</style>