import { DISCOUNT_TYPE } from '../vars';

const helpers = {};

helpers.typePercent = (discount) => {
    return discount && discount.type === DISCOUNT_TYPE.PERCENT;
};

helpers.getPrice = ({ price, discount }) => {
    return discount && discount.type === DISCOUNT_TYPE.PERCENT ? Math.ceil(price * ((100 - discount.percent) / 100)) : price;
};

export default helpers;